<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">
        <app-i18n code="home.menu"></app-i18n>
      </el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/human-resource' }">
        <app-i18n code="entities.humanResource.menu"></app-i18n>
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        <app-i18n code="entities.humanResource.view.title"></app-i18n>
      </el-breadcrumb-item>
    </el-breadcrumb>

    <div class="app-content-page">
      <h1 class="app-content-title">
        <app-i18n code="entities.humanResource.view.title"></app-i18n>
      </h1>

      <div
        class="app-page-spinner"
        v-if="loading"
        v-loading="loading"
      ></div>

      <app-human-resource-view-toolbar
        v-if="record"
      ></app-human-resource-view-toolbar>

      <el-form
        :label-position="labelPosition"
        :label-width="labelWidthForm"
        @submit.prevent.native
        class="form"
        v-if="record"
      >
        <app-view-item-text
          :label="fields.contactEmail.label"
          :value="presenter(record, 'contactEmail')"
        ></app-view-item-text>

        <app-view-item-text
          :label="fields.contactPerson.label"
          :value="presenter(record, 'contactPerson')"
        ></app-view-item-text>

        <app-view-item-text
          :label="fields.phoneNumber.label"
          :value="presenter(record, 'phoneNumber')"
        ></app-view-item-text>

        <app-view-item-relation-to-one
          :label="fields.user.label"
          :readPermission="fields.user.readPermission"
          :navigatePermission="fields.user.navigatePermission"
          :url="fields.user.viewUrl"
          :value="presenter(record, 'user')"
        ></app-view-item-relation-to-one>

        <app-view-item-text
          :label="fields.createdAt.label"
          :value="presenter(record, 'createdAt')"
        ></app-view-item-text>

        <app-view-item-text
          :label="fields.updatedAt.label"
          :value="presenter(record, 'updatedAt')"
        ></app-view-item-text>
      </el-form>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import HumanResourceViewToolbar from '@/modules/human-resource/components/human-resource-view-toolbar.vue';
import { HumanResourceModel } from '@/modules/human-resource/human-resource-model';

const { fields } = HumanResourceModel;

export default {
  name: 'app-human-resource-view-page',

  props: ['id'],

  components: {
    [HumanResourceViewToolbar.name]: HumanResourceViewToolbar,
  },

  computed: {
    ...mapGetters({
      labelPosition: 'layout/labelPosition',
      labelWidthForm: 'layout/labelWidthForm',
      record: 'humanResource/view/record',
      loading: 'humanResource/view/loading',
    }),

    fields() {
      return fields;
    },
  },

  async created() {
    await this.doFind(this.id);
  },

  methods: {
    ...mapActions({
      doFind: 'humanResource/view/doFind',
    }),

    presenter(record, fieldName) {
      return HumanResourceModel.presenter(record, fieldName);
    },
  },
};
</script>
